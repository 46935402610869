import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';

function Pindah_Saldo(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");    
    const [data_kasir, setDataKasir] = useState([]);
    const [kasir, setKasir] = useState("");
    const [saldo, setSaldo] = useState(0);
    const [nominal, setNominal] = useState(0);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pengaturan/tampil_pindah_saldo.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.saldo);
                    setDataKasir(data.data);
                }
                else {
                    setSaldo(0);
                    setDataKasir([]);
                }
            })
            .catch(error => {
                setSaldo(0);
                setDataKasir([]);
            }
            );
    }, [props.aplikasi]);

    const bersih = () => {
        fetch(props.aplikasi + '/pengaturan/tampil_pindah_saldo.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.saldo);
                    setDataKasir(data.data);
                }
                else {
                    setSaldo(0);
                    setDataKasir([]);
                }
            })
            .catch(error => {
                setSaldo(0);
                setDataKasir([]);
            }
            );

        setKasir("");
        setNominal(0);
    }

    const ubah_nominal = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setNominal(event.target.value);
    }

    const perbaiki_nominal = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setNominal(event.target.value);
        }
        else {
            setNominal(0);
        }
    }

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const simpan = () => {
        if (kasir === "") {
            setJenisNotif("warning");
            setIsiNotif("Kasir wajib diisi");
            setTampilNotif(true);
            document.getElementById("kasir").focus();
            return;
        }

        if (parseInt(nominal) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Nominal wajib diisi");
            setTampilNotif(true);
            document.getElementById("nominal").focus();
            return;
        }

        if (parseInt(nominal) > parseInt(saldo)) {
            setJenisNotif("warning");
            setIsiNotif("Nominal tidak boleh melebihi saldo pusat");
            setTampilNotif(true);
            document.getElementById("nominal").focus();
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/pengaturan/simpan_pindah_saldo.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        kasir: kasir,
                        nominal: nominal
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Saldo Pusat
                                </Typography    >
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                    Rp. {format_rupiah(parseInt(saldo))}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Kasir Tujuan
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        id="kasir"
                                        variant="outlined"
                                        fullWidth
                                        value={kasir}
                                        onChange={(event) => setKasir(event.target.value)}
                                    >
                                        {data_kasir.map((data_kasir) => {
                                            return (
                                                <MenuItem key={data_kasir.id_pengguna} value={data_kasir.id_pengguna}>{data_kasir.nama}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Nominal
                                </Typography>
                            </Grid>

                            <Grid container item spacing={1} xs={8} sm={8} md={9}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="nominal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={nominal}
                                        onChange={(e) => ubah_nominal(e)}
                                        onBlur={(e) => perbaiki_nominal(e)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                        Rp. {format_rupiah(parseInt(nominal))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveOutlined />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                                    loading={proses}
                                    onClick={() => simpan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Simpan</Typography>
                                </LoadingButton>
                            </Grid>
                        </Grid> 
                    </Grid>
                </Card>
            </Box>
        </div>
    );
}

export default forwardRef(Pindah_Saldo);